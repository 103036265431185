.view {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
  }
  
  .view svg {
    cursor: pointer;
    fill: #fff;
    position: absolute;
    width: 0;
    will-change: width;
    transition: width 0.3s;
  }
  
  .view.active {
    padding: 2rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  .view.active svg {
    width: 1rem;
  }
  
  .view.active svg:hover {
    width: 1.2rem;
  }
  
  .view .cancel {
    top: 1rem;
    right: 1rem;
  }
  
  .view + .loader {
    border: 3px solid hsla(185, 100%, 100%, 0.5);
    border-top-color: #fff;
    border-radius: 50%;
    width: 5em;
    height: 5em;
    animation: spin 1s linear infinite;
    display: none;
    position: fixed;
    z-index: 1;
    left: 45%;
    left: calc(50% - 2.5em);
    top: 45%;
  }
  
  .view.active + .loader {
    display: block;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  